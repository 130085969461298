<template>
  <el-row class="row" :gutter="20" v-loading="loadinga">
    <el-col :sm="24" :md="16">
      <el-card class="card-left-top">
        <div slot="header" class="clearfix">
          <span>签到板块</span>
        </div>
        <h2 v-if="is_signed_in_today" style="font-size: 40px; color: #40a0ffa0;">
          您今天已经签到过了噢
        </h2>
        <h2 v-else style="font-size: 40px; color: #F56C6Ca0;">
          您今日尚未签到
          <el-button type="primary" plain style="float: right;" @click="onSignButtonClick" slot="reference" v-loading="loadingc">
            立即签到
          </el-button>
        </h2>
        <p>每日签到即可获取1~300点积分，积分可用于购买增值服务</p>
        <el-divider></el-divider>
        <el-statistic ref="statistic" format="HH:mm:ss" :value="scqdsj" title="距离第二天还剩" time-indices></el-statistic>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="12" :lg="6">
            <div>
              <el-statistic title="上次签到时间">
                <template slot="formatter">
                  <i class="el-icon-time iconbianda"></i>
                  <p>{{ lastSignInTime }}</p>
                </template>
              </el-statistic>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :lg="6">
            <div>
              <el-statistic title="累计签到积分">
                <template slot="formatter">
                  <i class="el-icon-coin iconbianda"></i>
                  <p>{{ totalPoints }} 分</p>
                </template>
              </el-statistic>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :lg="6">
            <div>
              <el-statistic title="累计签到次数">
                <template slot="formatter">
                  <i class="el-icon-news iconbianda"></i>
                  <p>{{ totalSignIns }} 次</p>
                </template>
              </el-statistic>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :lg="6">
            <div>
              <el-statistic title="今日签到人数">
                <template slot="formatter">
                  <i class="el-icon-user iconbianda"></i>
                  <p>{{ todaySignInCount }} 人</p>
                </template>
              </el-statistic>
            </div>
          </el-col>
        </el-row>
      </el-card>
    </el-col>
    <el-col :sm="24" :md="8">
      <el-card class="card-right">
        <div slot="header">
          <span>个人详情</span>
          <el-button style="float: right; padding: 3px 0" type="text" @click="logout">退出登录</el-button>
        </div>
        <div class="jzjzjz">
          <div>
            <el-avatar :size="100" :src="circleUrl"></el-avatar>
          </div>
          <h1>{{ name }}</h1>
          <el-descriptions :column="2">
            <el-descriptions-item label="用户名">{{ name }}</el-descriptions-item>
            <el-descriptions-item label="剩余积分">{{ integral }} 点</el-descriptions-item>
            <el-descriptions-item label="注册邮箱">{{ email }}</el-descriptions-item>
            <el-descriptions-item label="注册QQ">{{ qq }}</el-descriptions-item>
            <el-descriptions-item label="权限组">{{ usergroup }}</el-descriptions-item>
            <el-descriptions-item label="到期时间">{{ term }}</el-descriptions-item>
            <el-descriptions-item label="实名状态">{{ realname }}</el-descriptions-item>
            <el-descriptions-item label="带宽限制">{{ bandwidth }} Mbps</el-descriptions-item>
            <el-descriptions-item label="隧道限制">{{ tunnel }}</el-descriptions-item>
            <el-descriptions-item label="隧道总数">{{ tunnelstate }}</el-descriptions-item>
          </el-descriptions>
          <span>用户唯一标识码：{{ usertoken }}</span>
          <el-divider></el-divider>
          <el-row :gutter="10">
            <el-col :xs="24" :sm="16" :md="16" :lg="8" :xl="6">
              <el-button type="primary" plain @click="showExchangeDialog">兑换码</el-button>
            </el-col>
            <el-col :xs="24" :sm="16" :md="16" :lg="8" :xl="6">
              <el-button type="danger" plain @click="resetToken">重置token</el-button>
            </el-col>
          </el-row>
        </div>
      </el-card>
      <el-dialog title="兑换码" :visible.sync="exchangeDialogVisible" width="30%">
        <el-input v-model="giftcode" placeholder="请输入礼品码"></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="exchangeDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="exchangegiftcode" v-loading="loadingb">确定</el-button>
        </span>
      </el-dialog>
      <!-- <el-card class="card-right-x">
      </el-card> -->
    </el-col>
  </el-row>
</template>

<script>
import { setToken, getToken, removeToken } from '@/utils/setToken.js';
import axios from 'axios';

export default {
  data() {
    return {
      rename: false,
      loadingyx: false,
      loadinga: false,
      loadingb: false,
      loadingc: false,
      loadingtt: false,
      is_signed_in_today: false,
      circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      giftcode: '',
      exchangeDialogVisible: false,
      scqdsj: Date.now() + (new Date().setHours(23, 59, 59) - Date.now()),
      todaySignInCount: '',
      totalSignIns: '',
      totalPoints: '',
      lastSignInTime: '',
      cooldown: 0,
      top: '',
      renameform: {
        newname: '',
        verificationCode: '',
      },
    }
  },

  mounted() {
    this.qiandaoinfo();
  },

  created() {
    this.generateRandomString();
    const usertoken = getToken('token');
    this.email = getToken('email');
    this.qq = getToken('qq');
    this.realname = getToken('realname');
    this.usergroup = getToken('usergroup');
    this.term = getToken('term');
    this.tunnel = getToken('tunnel');
    this.bandwidth = getToken('bandwidth');
    this.tunnelstate = getToken('tunnelstate');
    this.usertoken = getToken('token');
    this.name = getToken('username');
    this.circleUrl = getToken('userimg');
    this.integral = getToken('integral');

    if (usertoken) {
      this.setData();
    } else {
      removeToken('token');
      this.$router.push('/login');
      this.$message({
        message: "无效的token，请重新登录",
        type: "error",
      });
    }
  },
  methods: {
    qiandaoinfo(params) {
      const userid = getToken('userid');
      this.loadingb = true,
        axios
          .get('https://cf-v1.uapis.cn/api/qdxx.php', {
            params: { userid, ...params },
          })
          .then(response => {
            this.lastSignInTime = response.data.last_sign_in_time;
            this.totalPoints = response.data.total_points;
            this.totalSignIns = response.data.total_sign_ins;
            this.todaySignInCount = response.data.count_of_matching_records;
            this.is_signed_in_today = response.data.is_signed_in_today;
          })
          .finally(() => {
            this.loadingb = false; //结束确认按钮加载状态
          });
    },

    generateRandomString() {
      let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';

      for (let i = 0; i < 12; i++) {
        let randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
      }

      this.randomString = result;
    },

    setData(params) {
      this.loadinga = true;
      const usertoken = getToken('token');
      axios
        .get('https://cf-v1.uapis.cn/api/userinfo.php', {
          params: { usertoken, ...params },
        })
        .then(response => {
          if (response.data.error) {
            removeToken('token');
            this.$router.push('/login');
            this.$message({
              message: "无效的token，请重新登录",
              type: "error",
            });
          } else {
            const { userid, username, email, qq, usergroup, bandwidth, tunnel, realname, integral, userimg, term, tunnelstate } = response.data;

            setToken('userid', userid);
            setToken('username', username);
            setToken('email', email);
            setToken('qq', qq);
            setToken('usergroup', usergroup);
            setToken('bandwidth', bandwidth);
            setToken('tunnel', tunnel);
            setToken('realname', realname);
            setToken('integral', integral);
            setToken('userimg', userimg);
            setToken('term', term);
            setToken('tunnelstate', tunnelstate);
          }
        })
        .catch(error => {
          console.error('请求API时发生错误:', error);
        })
        .finally(() => {
          this.loadinga = false;
        })
    },
    resetToken() {
      // 弹出确认框
      this.$confirm('确定要重置token吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        // 用户点击确定，调用重置token的API
        this.loadinga = true;
        const usertoken = getToken('token');
        axios
          .get('https://cf-v1.uapis.cn/api/resusertoken.php', {
            params: { usertoken: usertoken }
          })
          .then(response => {
            if (response.data.error) {
              this.$message({
                message: "重置token失败：" + response.data.error,
                type: "error",
              });
            } else {
              // 重置token成功
              this.setData();
              this.$message({
                message: "重置token成功",
                type: "success",
              });
            }
          })
          .catch(error => {
            console.error('请求API时发生错误:', error);
            this.$message({
              message: "重置失败，请求API时发送错误",
              type: "error",
            });
          })
          .finally(() => {
            this.loadinga = false;
          });
      }).catch(() => {
        // 用户点取消
      });
    },

    // 点击“兑换码”按钮后弹出输入礼品码的弹窗
    showExchangeDialog() {
      this.exchangeDialogVisible = true;
    },
    // 提交礼品码
    exchangegiftcode() {
      // 关闭兑换码弹窗
      this.loadingb = true;

      // 调用API，并传递礼品码和其他参数
      const usertoken = getToken('token');
      const userid = getToken('userid');
      axios
        .get('https://cf-v1.uapis.cn/api/giftcode.php', {
          params: { usertoken, userid, giftcode: this.giftcode },
        })
        .then(response => {
          if (response.data.success === true) {
            this.$message({
              message: "兑换码兑换成功",
              type: "success",
            });
          } else {
            this.$message({
              message: response.data.message,
              type: "error",
            });
          }
        })
        .finally(() => {
          this.loadingb = false;
          this.exchangeDialogVisible = false;
        })
        .catch(error => {
          console.error('请求API时发生错误:', error);
          this.$message({
            message: "兑换礼品码失败，请求API时发送错误",
            type: "error",
          })
        })
    },
    logout() {
      // 弹出确认框
      this.$confirm('确定要退出登录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message.success('退出登录成功')
        removeToken('token');
        removeToken('username');
        removeToken('userid');
        removeToken('userimg');
        removeToken('email');
        removeToken('tunnel');
        removeToken('usergroup');
        removeToken('qq');
        removeToken('bandwidth');
        removeToken('realname');
        removeToken('integral');
        removeToken('term');
        this.$router.push('/login');
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '您已取消'
        });
      });
    },
    onSignButtonClick() {
      this.loadingc = true;
      window.initGeetest4(
        {
          product: 'bind',
          captchaId: '3891b578aa85e4866c5f8205b02b165a',
          width: '100%',
        },
        (captchaObj) => {
          captchaObj.showCaptcha();
          captchaObj.onSuccess(() => {
            const result = captchaObj.getValidate();
            if (result) {
              console.log('Geetest 验证成功:', result);
              // 调用签到API
              this.signIn(result);
            }
          });
        }
      );
    },
    async signIn(geetestResult) {
      const usertoken = getToken('token');
      try {
        const response = await axios.post('https://cf-v2.uapis.cn/qiandao', {
          token: usertoken,
          captcha_output: geetestResult.captcha_output,
          lot_number: geetestResult.lot_number,
          pass_token: geetestResult.pass_token,
          gen_time: geetestResult.gen_time,
        }, {
          headers: {
            'Content-Type': 'application/json',
          }
        });
        const data = response.data;
        if (data.state === 'success') {
          this.$alert(data.msg, {
            confirmButtonText: '确定',
            type: 'success',
          }).then(() => {
            this.$message({
              type: 'success',
              message: '签到成功'
            });
            location.reload();
          }).finally(() => {
            this.loadingc = false;
          });
        } else {
          this.$message({
            type: 'error',
            message: '签到失败：' + data.msg
          });
          this.loadingc = false;
        }
      } catch (error) {
        this.$message({
          type: 'error',
          message: '签到API请求失败：' + error
        });
        this.loadingc = false;
      }
      setTimeout(() => {
        this.loadingc = false;
        this.qiandaoinfo();
      }, 3000);
    }
  }
}
</script>

<style lang="less" scoped>
.jzjzjz {
  text-align: center;
}

.bolder {
  font-weight: bolder;
}

.fa {
  font-size: 25px;
}

.row {
  min-height: 340px;

  .iconbianda {
    font-size: 50px;
  }

  .card-left-top {
    min-height: 340px;
  }

  .card-right-x {
    min-height: 200px;
    margin-top: 16px;
  }

  .card-right {
    min-height: 340px;
  }
}
</style>